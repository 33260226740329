<template>
  <div class="container-fluid pb-3">
    <label for="templateEditor" class="card-title p-3 m-0">Plantilla</label>

    <!-- <textarea :id="editorId" v-model="template">
     </textarea>-->
    <editor
        api-key="h8ixugynu6nzgobv5yb4xs685tj8k9o5rgdk10u1nru42vjb"
        :init="editorConfig"
        :initial-value="template"
        @onChange="editorChange"
        @onSaveContent="() => {
          this.$emit('save-template', false)
        }"
    />

    <!-- TAGS MODAL -->
    <div class="modal fade" id="tagsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Etiquetas disponibles</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p v-if="!tags.length">
              No has declarado ninguna etiqueta. Ve a
              <router-link to="/company-data" target='_blank'>DATOS DE EMPRESA</router-link>
              para añadir etiquetas.
            </p>

            <div v-if="tags.length">
              <p>Selecciona la etiqueta que quieras añadir. También puedes
                <router-link to="/company-data" target='_blank'>crear más etiquetas aquí.</router-link>
              </p>
              <p class="text-muted font-italic">
                <font-awesome-icon icon="info" fixed-width/>
                Si acabas de añadir una etiqueta y no te aparece aquí, cierra y abra de nuevo este diálogo.
              </p>
              <ul class="list-group">
                <button class="list-group-item hoverable mb-1"
                        v-for="(data, index) in tags"
                        @click="addTagFromModal(index)"
                >
                  <strong>[{{ data.name || data.tag }}]</strong>: {{ data.value }}
                </button>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn text-muted" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
    <!--/ TAGS MODAL -->

  </div>


</template>

<script>

export default {
  props: ['template'],
  data() {
    return {
      editor: null,
      tags: [],
      editorConfig: {
        plugins: 'print preview paste importcss searchreplace autolink autosave directionality code ' +
            'visualblocks visualchars fullscreen image link media template codesample table charmap hr ' +
            'pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern ' +
            'noneditable help charmap quickbars emoticons',
        imagetools_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar: 'addTagButton || undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
            'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
            'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | ' +
            'insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        autosave_ask_before_unload: false,
        image_advtab: true,
        importcss_append: true,
        templates: [
          {
            title: 'New Table',
            description: 'creates a new table',
            content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
          },
          {title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...'},
          {
            title: 'New list with dates',
            description: 'New List with dates',
            content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>'
          }
        ],
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        height: 600,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image imagetools table',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        init_instance_callback: (editor) => {
          editor.addShortcut("ctrl+s", "Custom Ctrl+S", "custom_ctrl_s");
          editor.addCommand("custom_ctrl_s", () => {
            this.$emit('save-template', this.template);
          });
        },
        setup: (editor) => {
          this.editor = editor;

          editor.ui.registry.addButton('addTagButton', {
            text: 'AÑADIR ETIQUETA',
            tooltip: 'Añade una etiqueta de la configuración de la aplicación',
            onAction: () => {
              $('#tagsModal').modal('show');
            }
          });

        }
      }
    };
  },
  mounted() {
    axios.get(`${window.baseUrl}/api/params`)
        .then(response => {
          if (response.status == 200) {
            this.tags = response.data || JSON.parse(localStorage.getItem(COMPANY_DATA)) || [];
          }
        })
  },

  methods: {
    editorChange(event, editor) {
      this.$emit('change-template', editor.getBody().innerHTML);
    },
    addTagFromModal(index) {
      $('#tagsModal').modal('hide');
      var tagName = this.tags[index].name || this.tags[index].tag;
      this.editor.insertContent('<strong>[' + tagName + ']</strong> ');
    }
  }
};
</script>
