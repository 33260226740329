<template>
    <div class="container-fluid w-100">
        <div class="row">
            <div class="col-12">
                <div class="card mb-5">
                    <div class="card-body">
                        <!-- TITLE -->
                        <div class="card-title h2 text-center">
                            Información de la empresa
                            <button class="btn text-info" v-tooltip="'Ver información'" @click="showInfo = !showInfo">
                                <font-awesome-icon icon="info"/>
                            </button>
                        </div>

                        <div class="mb-3 p-3 border" v-if="showInfo">
                            <p>En esta ventana se gestionan las etiquetas usadas en la generación de los documentos. Se deben declarar
                                una pareja de ETIQUETA:VALOR.
                                La ETIQUET será sustituida por el VALOR dentro del documento.</p>
                            Para usar la etiqueta, tan solo hay con ponerla dentro de corchetes en la a plantilla del documento. Por
                            ejemplo:
                            <div class="p-0 bg-light mb-2">
                                <small class="bg-info text-white p-1">plantilla</small>
                                <div class="p-3">
                                    Esto es un texto dentro del documento para el cliente <strong class="text-danger font-weight-bold">[ETIQUETA_NOMBRE-EMPRESA]</strong>
                                    que va a ver su nombre una vez generado el documento...
                                </div>
                            </div>
                            <div class="p-0 bg-light">
                                <small class="bg-info text-white p-1">resultado</small>
                                <div class="p-2">
                                    Esto es un texto dentro del documento para el cliente <strong class="text-danger font-weight-bold">DLT
                                    Code SL</strong> que va a ver su nombre una vez generado el documento...
                                </div>
                            </div>
                        </div>
                        <!--/ TITLE -->

                        <div class="input-group mb-2">
                            <div class="input-group-prepend px-1">
                                <input type="text" class="form-control border-0 bg-white" value="NOMBRE" disabled>
                                <button class="btn" disabled>:</button>
                            </div>
                            <input type="text" class="form-control border-0 bg-white" placeholder="Valor"
                                   value="VALOR" disabled>
                        </div>
                        <!-- TAG INFO -->
                        <div class="input-group mb-2" v-for="(param, index) in params">
                            <div class="input-group-prepend px-1">
                                <input type="text" class="form-control " placeholder="Etiqueta" v-model="param.name"
                                       @keyup="normalizeparams()">
                                <button class="btn" disabled>:</button>
                            </div>
                            <input type="text" class="form-control" placeholder="Valor" v-model="param.value"
                                   @keyup.enter.prevent="saveInfo()"
                                   @keyup="normalizeparams()">

                            <button class="btn" @click="deleteParam(param, index)" v-tooltip="`Eliminar etiqueta ${param.name || ''}`">
                                <font-awesome-icon icon="times"/>
                            </button>
                        </div>
                        <!--/ TAG INFO -->

                        <button class="btn btn-outline-muted btn-block mt-3"
                                @click="newTag" v-tooltip="'Añadir nuevo campo de información'">
                            <font-awesome-icon icon="plus"/>
                        </button>

                        <button class="btn btn-outline-success btn-block mt-3" @click="saveInfo()"
                                v-tooltip="'Guardar etiquetas'">
                            <font-awesome-icon icon="save"/>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            params: [],
            showInfo: false,
        }
    },


    mounted() {
        this.loadParams();
        this.normalizeparams();
    },

    methods: {
        saveInfo(param) {
            this.normalizeparams();

            if (param) {
                this.saveParam(param);
            } else {
                this.params.forEach(param => {
                    if (param.name) {
                        this.saveParam(param);
                    }
                });

                this.$toast.success('Etiquetas guardadas con éxito')
            }
        },

        newTag() {
            this.params.push({tag: '', value: ''});
        },

        saveParam(param) {
            this.normalizeparams();

            axios.post(`${window.baseUrl}/api/params`, {
                id: param.id ?? null,
                name: param.name || param.tag,
                value: param.value
            }).then(response => {
                if (response.status == 200) {
                    param = response.data;
                }
            });
        },

        loadParams() {
            axios.get(`${window.baseUrl}/api/params`)
                .then(response => {
                    if (response.status == 200) {
                        this.params = response.data;

                        // if no data retrieved, check if stored on localstorage
                        if (!this.params.length) {
                            let storedParams = JSON.parse(localStorage.getItem(COMPANY_DATA));

                            // if we have stored params, set them and save it
                            if (storedParams.length) {
                                this.params = storedParams;
                            }

                            // if no params stored anywhere, set default
                            if (!this.params.length) {
                                this.params = [
                                    {
                                        tag: 'NOMBRE-EMPRESA',
                                        value: '<NOMBRE DE EMPRESA>'
                                    },
                                    {
                                        tag: 'CIF',
                                        value: '<CIF DE LA EMPRESA>'
                                    },
                                    {
                                        tag: 'DIRECCION',
                                        value: '<DIRECCION FISCAL>'
                                    },
                                ];
                            }

                            this.saveInfo()
                        }

                        this.normalizeparams();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
        },

        deleteParam(param, index) {
            if (param.id) {
                axios.delete(`${window.baseUrl}/api/params/${param.id}`)
                    .then(response => {
                        if (response.status == 200) {
                            this.params.splice(index, 1);
                            this.$toast.default('Etiqueta eliminada');
                        }
                    });
            } else {
                this.params.splice(index, 1);
                this.$toast.default('Etiqueta eliminada');
            }
        },

        normalizeparams() {
            if (this.params && Array.isArray(this.params)) {
                this.params.forEach((param, index) => {
                    this.params[index].name = this.params[index].name || this.params[index].tag;
                    this.params[index].name = this.params[index].name.toUpperCase().replace(/[^0-9A-Z\-\_]/gi, '');
                });
            }
        }
    }
};
</script>
