<template>
    <div class="container-fluid w-100">
        <h1 class="mb-3" v-if="sections.length">Lista de politicas
            <button class="btn text-secondary" v-tooltip="'Crear nueva pólitica'" @click="newSection()">
                <font-awesome-icon icon="plus"></font-awesome-icon>
            </button>
        </h1>
        <div class="row">
            <!-- EACH POLICY -->
            <div class="col-12" v-for="(section, index) in (sections.length ? sections : [section])">
                <!-- EDITIGN MODE -->
                <transition name="slide">
                    <div class="card mb-5 shadow-lg" v-if="section && section.editMode">
                        <div class="card-body">
                            <div class="card-title text-primary h3">{{ section.id ? 'Modificar' : 'Nueva' }} política</div>
                            <div class="form-group">
                                <label>Nombre de la política</label>
                                <input v-model="section.name" type="text" class="form-control"
                                       placeholder="Nombre política" required>
                            </div>

                            <div class="form-group">
                                <label>Nombre de la política</label>
                                <textarea v-model="section.description" type="text" class="form-control"
                                          placeholder="Descripción">
              </textarea>
                            </div>

                            <div class="row m-0">
                                <button class="btn btn-muted col" @click="toggleEditMode(section, false)">Cancelar</button>
                                <button class="float-right btn btn-outline-success success col-12 col-md-6"
                                        @click="saveSection(section)">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
                <!--/ EDITIGN MODE -->

                <!-- SECTIONS -->
                <div v-if="section && !section.editMode" :id="`section-${section.id}`" class="card card-body mb-2 bt-3">
                    <p class="h4 d-inline-block" aria-expanded="true" :aria-controls="`#sectionColl${section.id}`">
                        {{ section.name }}
                        <!--<button class="btn" v-tooltip="`Editar política`" @click.stop="() => {section.editMode = true}">
                          <font-awesome-icon icon="edit"/>
                        </button>-->

                        <button class="btn text-danger" v-tooltip="`Eliminar política`" @click.stop="deleteSection(section)">
                            <font-awesome-icon icon="trash"/>
                        </button>
                    </p>

                    <p v-if="section.description" class="text-muted">
                        {{ section.description }}
                    </p>

                    <div class="col-12">
                        <!-- DOCUMENTS -->
                        <div :id="`sectionColl${section.id}`" class="row show mt-3">

                            <!--<h5 v-if="!section.templates.length" class="col-12 text-secondary">
                              No hay informes disponibles
                            </h5>-->

                            <!-- ADD BUTTON -->
                            <div class="col-12 col-md-6 col-xl-4 mb-3">
                                <router-link :to="`/section/${section.id}/template`">
                                    <div class="card h-100 text-center text-muted hoverable"
                                         v-tooltip="'Crear nuevo informe'">
                                        <div class="m-auto py-5">
                                            <font-awesome-icon size="2x" icon="plus" swap-opacity/>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-12 col-md-6 col-xl-4 mb-3" v-if="section.templates.length" :id="`template-${template.id}`"
                                 v-for="template in section.templates">
                                <div class="card hoverable h-100">
                                    <div class="card-body">
                                        <div class="card-title h5 font-weight-bold">{{ template.name }}</div>
                                        <p class="m-0">{{ template.description }}</p>
                                    </div>
                                    <div class="card-footer row px-0 m-0">
                                        <div class="col col-md-6 text-left">
                                            <button class="btn btn-primary" @click="printTemplate(template)" v-tooltip="'Generar Informe'">
                                                <font-awesome-icon icon="file-pdf"/>
                                                <span class="d-none d-md-inline-block">Generar</span>
                                            </button>
                                        </div>
                                        <div class="col col-md-6 text-right">
                                            <button class="btn btn-outline-danger" v-tooltip="'informe'"
                                                    @click="deleteTemplate(template)">
                                                <font-awesome-icon icon="trash"/>
                                            </button>

                                            <router-link :to="`/template/${template.id}`" v-tooltip="'Editar informe'">
                                                <button class="btn btn-outline-primary">
                                                    <font-awesome-icon icon="edit"/>
                                                </button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ DOCUMENTS -->
                    </div>
                </div>
                <!--/ SECTIONS -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    params: [],
    data() {
        return {
            sections: [],
            section: null,
        };
    },

    mounted() {
        // if we have secion id
        if (this.$route.params.section_id) {
            this.loadSection(this.$route.params.section_id);
        } else {
            this.loadSections();
        }

        this.loadParams();
    },

    methods: {
        loadSection(sectionId) {
            axios.get(`${window.baseUrl}/api/sections/${sectionId}`)
                .then((response) => {
                    this.section = response.data;
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
        },

        loadSections() {
            axios.get(`${window.baseUrl}/api/sections`)
                .then((response) => {
                    this.sections = response.data;
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
        },

        printTemplate(template) {
            var template = template.template;
            this.loadParams().then((tags) => {
                tags = this.params;

                if (tags && tags.length) {
                    for (const index in tags) {
                        template = template.replaceAll('[' + tags[index].name + ']', tags[index].value);
                        template = template.replaceAll('{' + tags[index].name + '}', tags[index].value);
                        template = template.replaceAll(tags[index].name, tags[index].value);
                    }
                }

                let mywindow = window.open(
                    "",
                    "PRINT",
                    "width=" + screen.availWidth + ",height=" + screen.availHeight
                );

                mywindow.document.write(
                    `<html><head>
                    <link href="/css/app.css" rel="stylesheet">
                    <title>Informe generado con ${document.title}</title>`
                );
                mywindow.document.write("</head><body>");
                // mywindow.document.write(this.getPreviewHtml());
                mywindow.document.write(template);
                mywindow.document.write("</body></html>");

                mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10*/

                setTimeout(function () {
                    mywindow.print();
                }, 300);

                setTimeout(function () {
                    mywindow.close();
                }, 1300);
            }).catch(err => console.error(err));

            return true;
        },

        loadParams() {
            console.log('LOADING PARAMS');

            return axios.get(`${window.baseUrl}/api/params`)
                .then(response => {
                    if (response.status == 200) {
                        this.params = response.data;
                        this.normalizeparams();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
        },

        normalizeparams() {
            if (this.params && Array.isArray(this.params)) {
                this.params.forEach((param, index) => {
                    this.params[index].name = this.params[index].name || this.params[index].tag;
                    this.params[index].name = this.params[index].name.toUpperCase().replace(/[^0-9A-Z\-\_]/gi, '');
                });
            }
        },


        deleteTemplate: function (template) {
            this.$confirm("¿Seguro que quieres eliminar el informe?", `Eliminar informe ${template.name}`, "warning")
                .then(() => {
                    this.saving = true;

                    axios.delete(`${window.baseUrl}/api/templates/${template.id}`)
                        .then((response) => {
                            if (response.data) {
                                this.$toast.success(`Informe eliminada con éxito</b>`, {
                                    duration: 5000
                                });
                                this.loadSections()
                            } else {
                                this.$toast.error('Hubo un error al guardar el ifrome', {
                                    duration: 5000
                                });
                            }
                        })
                        .catch(function (error) {
                            // handle error
                            console.error(error);
                        })
                        .then(() => {
                            this.saving = false;
                        });

                    this.saving = false;
                    //do something...
                });
        },

        newSection() {
            this.sections.unshift({
                name: '',
                description: '',
                editMode: true
            })
        },

        saveSection(section) {
            if (!section.name) {
                this.$alert('Debes introducir un nombre de política', 'Fala el nombre', 'error')
                return false;
            }
            axios.post(`${window.baseUrl}/api/sections`, section)
                .then((response) => {
                    if (response && response.data) {
                        this.$toast.success(`Política <b><a href="#section-${response.data.id}">${response.data.name}</a></b> guardada con éxito`)
                        this.loadSections();
                    } else {
                        this.$toast.error('Hubo un error al guardar la política', {
                            duration: 5000
                        });
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                    this.$toast.error('Hubo un error al guardar la política', {
                        duration: 5000
                    });
                });
        },


        toggleEditMode(section, mode = true) {
            // if no id, delete it from list
            if (!section.id) {
                this.sections.shift();
            } else {
                section.editMode = mode;
            }
        },

        deleteSection(section) {
            this.$confirm(`Se eliminarán TODOS LOS INFOMRES junto a la política... ¿ESTÁS SEGURO?`, 'CUIDADO', 'warning')
                .then(() => {
                    axios.delete(`${window.baseUrl}/api/sections/${section.id}`)
                        .then((response) => {
                            if (response.data) {
                                this.$toast.success(`Política <b>${section.name}</b> eliminada con éxito`, {
                                    duration: 5000
                                });
                                this.loadSections();
                            } else {
                                this.$toast.error('Hubo un error al eliminar la política', {
                                    duration: 5000
                                });
                            }
                        })
                        .catch(function (error) {
                            // handle error
                            console.error(error);
                            this.$toast.error('Hubo un error al eliminar la política', {
                                duration: 5000
                            });
                        });
                })
        },

    },

};
</script>
