<template>
  <div class="template-component container-fluid w-100"
       @keydown.ctrl.83.prevent.stop="saveTemplate">
    <h3 class="mb-3">
      <router-link to="/" class="btn mr-2" v-tooltip="'Volver al listado de políticas'">
        <font-awesome-icon icon="chevron-left"/>
      </router-link>
      {{ template && template.id ? 'Modificando' : 'Creando' }} informe
    </h3>

    <span v-if="template">
      <!-- LOADER SAVING -->
      <transition name="fade">
        <div class="loader-wrapper" v-if="saving">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </transition>
      <!--/ SAVING -->

      <!-- TEMPLATE DATA -->
      <div class="card mb-3 shadow">
        <div class="card-body">
          <div class="form-group">
            <label for="tempName">Nombre de informe</label>
            <input id="tempName" v-if="template" v-model="template.name" type="text" class="form-control"
                   placeholder="Nombre informe" v-on:keyup.enter="saveTemplate()" required :autofocus="!template.id">
          </div>

          <div class="form-group">
            <label for="tempDesc">Descripción</label>
            <textarea id="tempDesc" v-model="template.description" type="text" class="form-control"
                      placeholder="Descripción informe">
            </textarea>
          </div>

          <button v-if="template.id" class="btn btn-outline-danger btn-block"
                  @click="deleteTemplate()"
                  v-tooltip="`Eliminar el informe <b>${template.name}</b>`">ELIMINAR INFORME</button>
        </div>
      </div>
      <!--/ TEMPALTE DATA -->

      <div class="card p-0 shadow">
        <template-editor
            :template="template.template"
            v-on:save-template="saveTemplate"
            v-on:change-template="tempalteChanged"
        ></template-editor>
      </div>
    </span>

    <button id="save-template-btn" class="mt-3 btn btn-success shadow" @click="saveTemplate()">
      GUARDAR
    </button>
  </div>
</template>

<script>

export default {
  data() {
    return {
      templateId: null,
      sectionId: null,
      template: null,
      saving: false,
    };
  },
  mounted() {
    this.templateId = this.$route.params.template_id;
    this.sectionId = this.$route.params.section_id;

    // if no tempalte id recieved, is creating new one
    if (this.sectionId) {
      this.template = {
        sectionId: this.sectionId,
        image: null,
        name: "",
        template: "",
      }
    } else if (this.templateId) {
      axios
          .get(`${window.baseUrl}/api/templates/${this.templateId}`)
          .then((response) => {
            this.template = response.data;
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
    }
  },

  methods: {
    deleteTemplate: function () {
      this.$confirm("¿Seguro que quieres eliminar el informe?", "Eliminar informe", "warning")
          .then(() => {
            this.saving = true;

            axios.delete(`${window.baseUrl}/api/templates/${this.template.id}`)
                .then((response) => {
                  if (response.data) {
                    this.$toast.success(`Informe <b>${this.template.name} eliminada con éxito</b>`, {
                      duration: 5000
                    });
                    this.$router.push('/');
                  } else {
                    this.$toast.error('Hubo un error al eliminar el ifrome', {
                      duration: 5000
                    });
                  }
                })
                .catch(function (error) {
                  // handle error
                  console.error(error);
                })
                .then(() => {
                  this.saving = false;
                });

            this.saving = false;
            //do something...
          });
    },

    saveTemplate: function (isTrueSaving = true) {
      // when window unload, tiny executes the save event...
      if(!isTrueSaving) {
          return false;
      }

      this.saving = true;
      // no name setted
      if (!this.template.name.trim().length) {
        this.$alert('Debes introducir un nombre de informe', 'Fala el nombre', 'error')
        this.saving = false;
      } else {
        axios.post(`${window.baseUrl}/api/templates`, this.template)
            .then((response) => {
              if (response && response.data) {
                this.template = response.data;
                this.$toast.success(`Informe <b>${this.template.name}</b> guardado con éxito`)
              } else {
                this.$toast.error('Hubo un error al guardar elemento', {
                  duration: 5000
                });
              }
            })
            .catch(function (error) {
              // handle error
              console.error(error);
            })
            .then(() => {
              this.saving = false;
            });
      }
    },

    tempalteChanged: function (template) {
      this.template.template = template;
    }
  }
};
</script>


