<script src="app.js"></script>
<template>
  <div class="row no-gutters h-100">
    <!-- LEFT MENU -->
    <div class="memu-wrapper col bg-white" id="sidebar-wrapper" :class="{'menu-toggled': this.menuToggled}">
      <ul class="menu-list list-group">
        <li class="menu-title h4 m-0">
          <span class="title">GRC</span>
        </li>

        <router-link to="/company-data" active-class="active" class="menu-item list-group-item">
            <font-awesome-icon fas icon="pen-square" class="menu-icon"/>
            <span class="menu-text">DATOS DE EMPRESA</span>
        </router-link>

        <router-link to="/sections" active-class="active" class="menu-item list-group-item">
            <font-awesome-icon fas icon="copy"/>
            <span class="menu-text">POLITICAS</span>
        </router-link>

      </ul>
    </div>
    <!--/ LEFT MENU -->

    <div class="col h-full">
      <!-- TOP NAVBAR -->
      <nav class="navbar">
        <button class="btn" @click="toggleMenu">
          <font-awesome-icon fas icon="bars"/>
        </button>
        <span class="navbar-brand">
          {{ appName }}
        </span>

        <div class="float-right">
          <a class="btn" href="/logout" v-tooltip="'Cerrar sesión'">
            <font-awesome-icon fas icon="power-off"/>
          </a>
        </div>
      </nav>
      <!--/ TOP NAVBAR -->

      <router-view></router-view>
    </div>
  </div>
</template>

<script>

export default {
  props: ['appName'],
  data() {
    return {
      menuToggled: false,
    }
  },

  mounted() {
    this.menuToggled = JSON.parse(localStorage.getItem('menuToggled')) || false;
  },

  methods: {
    toggleMenu() {
      this.menuToggled = !this.menuToggled;
      localStorage.setItem('menuToggled', this.menuToggled);
    }
  }
};
</script>
