import VueRouter from "vue-router";
import SectionsList from "./views/SectionsList";
import Template from "./views/Template";
import CompanyData from "./views/UserParams";

const router = new VueRouter({
    routes: [
        {
            path: '/sections',
            name: 'sections',
            component: SectionsList
        },
        {
            path: '/sections/:section_id',
            name: 'sections.detail',
            component: SectionsList
        },
        {
            path: '/section/:section_id/template',
            name: 'templateCreate',
            component: Template
        },
        {
            path: '/template/:template_id?',
            name: 'template',
            component: Template
        },
        {
            path: '/company-data',
            name: 'company-data',
            component: CompanyData
        },
        {
            path: "*",
            redirect: "/sections"
        }
    ]
});

export {router};
