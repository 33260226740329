/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./constants');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./views/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map((key) => {
    Vue.component(key.split('/').pop().split('.')[0], files(key).default)
})

import VueRouter from 'vue-router'
Vue.use(VueRouter);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueToast from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

// TinyMCE
import Editor from '@tinymce/tinymce-vue'
Vue.component('editor', Editor)

// fontawesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserSecret} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'

library.add(fab)
library.add(fas)

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import {router} from './router';

const app = new Vue({
    el: '#app',
    router,
    components: {
        'editor': Editor // <- Important part
    },
});
